import React from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Types from '../components/types'

// Mailchimp
import Subscribe from '../components/subscribe'

import styles from "../styles/index.module.css"

import benefits from "../styles/benefit.module.css"
import features from "../styles/feature.module.css"

// Help
import iconKeep from '../images/keep.svg';
import iconReduceCost from '../images/reduce_cost.svg';
import iconAttract from '../images/attract.svg';
import iconIncreaseSales from '../images/increase_sales.svg';

const IndexPage = (props) => (
  <Layout>
    <div className="index">
      <Hero></Hero>
      <section className={benefits.wrap}>
        <h2 className={benefits.title}>Программы лояльности помогут</h2>
        <ul className={benefits.list}>
          <li className={benefits.item}>
            <img src={iconKeep} alt="Удержать клиента" className={benefits.icon} />
            <p>
              <strong>Удержать клиента</strong>
              если у клиента накопились бонусы  или предоставлена постоянная скидка, то клиент скорее останется с Вами
            </p>
          </li>
          <li className={benefits.item}>
            <img src={iconReduceCost} alt="Снизить затраты на рекламу" className={benefits.icon} />
            <p>
              <strong>Снизить затраты на рекламу</strong>
              издержки с новым клиентом выше, чем  с постоянным, так как стоимость привлечения клиента намного выше стоимости удержания
            </p>
          </li>
          <li className={benefits.item}>
            <img src={iconAttract} alt="Привлечь новых клиентов" className={benefits.icon} />
            <p>
              <strong>Привлечь новых клиентов</strong>
              Клиенты могут делиться своими бонусами или скидкой с друзьями и родственниками
            </p>
          </li>
          <li className={benefits.item}>
            <img src={iconIncreaseSales} alt="Увеличить продажи" className={benefits.icon} />
            <p>
              <strong>Увеличить продажи</strong>
              чем больше скидка или ближе вознаграждение, тем чаще совершаются покупки
            </p>
          </li>
        </ul>
      </section>
      <section className={features.wrap}>
        <div className={features.card}>
          <h2>Почему наши программы лояльности дешевле?</h2>
          <p>Чтобы начать работу достаточно мобильного телефона</p>
          <ul className={features.table}>
            <li>
              <h3>Программы лояльности от LoyaltyPro</h3>
            </li>
            <li>
              <strong>Достаточно мобильного телефона/планшета на точке продаж или установить “плагин” для вашего интернет-магазина</strong>
            </li>
            <li>
              <strong>Не требуется специальных навыков</strong>
              интеграция с кассовым программным обеспечением или чат-ботами
            </li>
            <li>
              <strong>Информационные материалы</strong>
              Реклама о запуске бонусной программы
            </li>
            <li>
              <strong>Досточно номера телефона или email</strong>
              неограниченное количество бесплатных виртуальных карт
            </li>
            <li>
              <strong>Служба поддержки</strong>
              Вам достаточно рассказать Вашим клиентам о бонусной программе, техническая поддержка - наша забота
            </li>
            <li>
              <h3>от 500 рублей/месяц</h3>
            </li>
          </ul>
          <ul className={features.table}>
            <li>
              <h3>Затраты на программы лояльности в агентствах</h3>
            </li>
            <li>
              <strong>Затраты на оборудование и программное обеспечение</strong>
              База данных, считыватели карт, мобильные приложения (от&nbsp;400 000₽)
            </li>
            <li>
              <strong>Найм и тренинги персонала</strong>
              аналитик (от 45 000 р/мес), технический отдел (от 250 000 р/мес), команда маркетологов (от 30 000 р/мес)

            </li>
            <li>
              <strong>Информационные материалы</strong>
              Реклама о запуске бонусной программы
            </li>
            <li>
              <strong>Выпуск карт</strong>
              Печать карт от 5₽ за шт.
            </li>
            <li>
              <strong>Служба поддержки</strong>
              Обслуживание клиентов и технические вопросы
              <br/>
              (от 100 000 р/мес)
            </li>
            <li>
              <h3>от 800 000 рублей (внедрение)</h3>
              <h3>+ ежемесячные расходы</h3>
            </li>
          </ul>
        </div>
      </section>


      <Types></Types>

      <section className={styles.onboarding}>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <h1 className={styles.title}>Как работает loyaltypro?</h1>
            <p>
              Не нужно ничего скачивать и устанавливать.
              <br/>
              LoyaltyPro работает онлайн – через Интернет
            </p>
          </div>
        </div>
        <div className={styles.col}>
          <ul className={styles.steps}>
            <li>
              <h3>Зарегистрируйтесь</h3>
              <p>Расскажите о своём бизнесе, мы поможем выбрать и настроить программу лояльности</p>
            </li>
            <li>
              <h3>Регистрируйте клиентов</h3>
              <p>Пластиковые карты необязательны достаточно телефонного номера или email</p>
            </li>
            <li>
              <h3>Анализируйте</h3>
              <p>Используйте личный кабинет для детальной оценки эффективности программы лояльности.
              (NPS, Look-a-like, размер среднего чека, когортный анализ, динамика совершения покупок, отток клиентов).</p>
            </li>
          </ul>
        </div>
      </section>
      <Subscribe></Subscribe>
    </div>
  </Layout>
)

export default IndexPage
