import React from 'react'
import { Link } from 'gatsby'

import styles from "../styles/hero.module.css"

// Icons
import shapesBg from '../images/vectors.svg';
import typesBg from '../images/types.svg';

const Hero = () => (
  <div className={styles.bg}>
    <div className={styles.wrap}>
      <h1 className={styles.title}>Платформа для запуска  программ лояльности</h1>
      <p className={styles.subtitle}>Поможет удержать и привлечь клиентов</p>
      <Link to="/signup/" className="button">Попробовать первым</Link>
    </div>
    <img src={shapesBg} alt="" className={styles.img} />
    <img src={typesBg} alt="" className={styles.img} />
  </div>
)

export default Hero
