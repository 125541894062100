import React from 'react'
import { Link } from 'gatsby'

import styles from "../styles/solutions.module.css"

// Icons
import iconCoffee from '../images/coffee.svg';
import iconCarService from '../images/car_service.svg';
import iconCarWash from '../images/car_wash.svg';
import iconCafe from '../images/cafe.svg';
import iconEcommerce from '../images/ecommerce.svg';
import iconB2B from '../images/b2b.svg';
import iconRetail from '../images/retail.svg';
import iconHR from '../images/hr.svg';

const Types = () => (
  <section className={styles.wrap}>
    <h2 className={styles.title}>Попробуйте готовые решения</h2>
    <strong>Программы лояльности от 500 рублей/месяц</strong>
    <ul className={styles.list}>
      <li className={styles.item}>
        <Link to="/solutions#coffee">
          <strong>Кофейня</strong>
          <img src={iconCoffee} alt="Кофейня" />
        </Link>

      </li>
      <li className={styles.item}>
        <Link to="/solutions#carservice">
          <strong>Авторемонтная мастерская</strong>
          <img src={iconCarService} alt="Авторемонтная мастерская" />
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/solutions#carwash">
          <strong>Автомойка</strong>
          <img src={iconCarWash} alt="Автомойка" />
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/solutions#cafe">
          <strong>Кафе и рестораны</strong>
          <img src={iconCafe} alt="Кафе" />
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/solutions#ecommerce">
          <strong>Интернет-магазин</strong>
          <img src={iconEcommerce} alt="Интернет-магазин" />
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/solutions#b2b">
          <strong>Партнерская сеть (b2b)</strong>
          <img src={iconB2B} alt="Партнерская сеть (b2b)" />
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/solutions#retail">
          <strong>Розничные магазины</strong>
          <img src={iconRetail} alt="Розничные магазины" />
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/solutions#hr">
          <strong>Мотивация сотрудников</strong>
          <img src={iconHR} alt="Мотивация сотрудников" />
        </Link>
      </li>
    </ul>
  </section>
)

export default Types
